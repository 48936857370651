.row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

@mixin column {
  display: flex;
  flex-direction: column;
}

.action {
  width: 25px;
  height: 25px;
}
