@import "./colors";

@font-face {
  font-family: MuseoSans700;
  src: url("fonts/MuseoSans_700.otf") format("opentype");
}

@font-face {
  font-family: MuseoSans500;
  src: url("fonts/MuseoSans_500.otf") format("opentype");
}

@font-face {
  font-family: MuseoSans300;
  src: url("fonts/MuseoSans_300.otf") format("opentype");
}

h1,
h2,
h3,
h4,
h5,
h6,
a,
button,
input,
span,
div,
label {
  font-family: MuseoSans500, sans-serif;
  color: $color-night-blue;
  font-size: 12pt;
}

a,
button {
  font-size: 1em;
}

p {
  font-family: MuseoSans300, sans-serif;
  font-size: 13pt;
}

input {
  font-size: 13pt;
}

h1 {
  font-family: MuseoSans500, sans-serif;
  font-size: 19pt;
}

h2 {
  font-family: MuseoSans500, sans-serif;
  font-size: 16pt;
}

h3 {
  font-family: MuseoSans700, sans-serif;
  font-size: 13pt;
}

span,
label {
  font-family: MuseoSans500, sans-serif;
  font-size: 11pt;
}

@mixin trimWithEllipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
