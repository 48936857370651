$color-night-blue: #003665;
$color-night-blue-transparent: #00366599;
$color-white: #ffffff;
$color-prussian-blue: #0036651a;
$color-prussian-navy-blue: #003665b3;
$color-navy: #00366580;
$color-kashmir-blue: #4d7293;
$color-black-squeeze: #f3f8fa;
$color-deep-sky-blue: #00aeef;
$color-mystic: #e6ebf0;
$color-aluminium-grey: #aaadb3;
$color-bali-hai-grey: #89a3b7;
$color-bali-hai: #859ab2;
$color-night-40: #99afc1;
$color-night-10: #e6ebf0;
$color-persian-red: #d64040;
