@import "styles/colors";
@import "styles/reset";
@import "styles/typography";

*,
:after,
:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body,
html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  padding-left: 6px;
  padding-right: 6px;
}
